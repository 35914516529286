@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Forum&family=Josefin+Sans:wght@300;400&display=swap');
*{
     /* background-color: #eae7dc; */
     box-sizing: border-box;
     
 }

 /* ----- STYLE ----- */


.navbar{
 display: flex;
 justify-content: space-between;
 align-items: center;
 align-content: flex-start;
 margin: 0px;
 border: 0px;
 padding: 0px;
} 

.logo{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-size: cover;
    background-image: url("../radPictures/RADLOGO.png");
    height: 200px;
    width: 200px;
}

.navbarContainer{
    display: flex;
    justify-content: space-between;
}

.page{
    display: flex;
    align-content: center;
    justify-content: center;
    order: 1;
    z-index: 1;
}

.navbar-links ul{
    margin: 0px;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    
}

.navbar-links li{
    list-style: none;
    font-family: 'Forum', cursive;
    justify-content: flex-end;
    padding-right: 50px;
    padding-left: 50px;
}

.navbar-links li:hover {
     background-color: goldenrod;
     transition: 550ms ease-in;
}


.navbar-links a {
    text-decoration: none;
    color: black;
    padding: 1rem;
    display: block;
}

.toggle-button {
    position: absolute;
    top: .75rem;
    right: 1rem;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 21px;
}

.toggle-button .bar {
    height: 3px;
    width: 100%;
    background-color: black;
    border-radius: 10px;
}


@media (max-width: 600px){

    .toggle-button{
        display: flex;
    }

    .navbar-links{
        display: none;
        width: 100%;
    }

    .navbar{
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
    }

    .navbar-links ul {
        /* width: 100%;
        flex-direction: column; */
    }

    .navbar-links li{
        /* text-align: center; */
    }

    .navbar-links li a {
        /* padding: .5rem 1rem; */
    }

    .navbar-links.active{
        display: flex;
    }

    .logo{
        display: none;
    }

}

/* ----- SLIDE STYLING ----- */

.containerStyles{
    display: flex;
    flex-direction: row;
    width: 500px;
    height: 280px;
    margin: 0 auto;
    justify-content: center;

    
}

.sliderStyles{
   height: 100%;
   position: realtive; 
   border-radius: 10px ;
}

.leftArrow{
    display: flex;
    font-size: 75px;
    color:white;
    cursor: pointer; 
    flex-direction: row;
    order: 1;
}

.rightArrow{
    display: flex;
    font-size: 75px;
    color: white;
    cursor: pointer; 
    flex-direction: row;
    order: 2;
}

.rightArrow:hover{
    transition: 550ms ease-in;
    color: goldenrod;
}

.leftArrow:hover{
    transition: 550ms ease-in;
    color: goldenrod;
}
 /* ----- PAGE SETUP ----- */

.pagecontainer{
     display: flex;
     flex-direction: column;
 }

.header{
    font-family: 'Forum', cursive;
    display: flex;
    align-items: center;
    justify-content: center;
}

.body div{
    font-family: 'Forum', cursive;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

button{
    display: flex;
    flex-direction: column;
    place-content: space-evenly;
    font-family: 'Forum', cursive;
    margin: 5px;
    background-color: whitesmoke;
    border: none;

}

button:hover{
    transition: 550ms ease-in-out;
    background-color: goldenrod;
   
}


#service{
    display: flex;
    justify-content: space-between;
    align-content: space-between;
    margin: 10px ;
    padding: 10px ;

}

.formstyle{
    font-family: 'Forum', cursive;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; 
    
}

.form{
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-right: 35vw;
    margin-left: 35vw;
}

input{
    font-family: 'Forum', cursive;
    margin: 5px
}

.calendar{
    font-family: 'Forum', cursive;
}


.return{
    display: flex;
    flex-direction: column;
    align-content:center ;
    
}


